@use "../abstracts/colours" as *;
@use "../abstracts/mixins" as *;

header {
  padding: 1% 2% 0;
}

nav {
  height: 30px;
  width: 100%;

  a {
    color: $darkMain;
    font-weight: bold;
    margin-right: 10px;
  }

  a.router-link-exact-active {
    color: $violetSphere;
  }
}

#separatorStripe {
  @include gradient(to left, $rainbowList);
  height: 30px;
  opacity: 0.69;
  width: 100%;
}
