@use "abstracts/colours" as *;
@use "abstracts/mixins" as *;
@use "abstracts/colourMatch";
@use "layout/header";
@use "pages/chroma";
@use "pages/darkstar-vale";
@use "pages/picture-info";

* {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: baseline;
}

#App {
  background-color: $background;
  color: $darkMain;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100dvh;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  width: 100vw;
}

a {
  color: $darkMain;
}

a:visited {
  color: $violetSphere;
}

main {
  padding: 10px;

  .description {
    li {
      margin-bottom: 14px;
    }
  }

  figure {
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-style: italic;
  }

  h1 {
    font-size: 1.4em;
  }

  h2 {
    display: inline;
    font-size: 1.2em;
    margin-top: 10px;
    text-align: left;
  }

  img {
    border-radius: 3px;
    max-width: 500px;
    width: 100%;
  }

  p {
    font-size: 0.8em;
  }

  .subtitle {
    display: block;
    font-style: italic;
  }
}
