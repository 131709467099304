$darkMain: #2c3e50;
$greyMain: #464343;
$blueMain: #9fcfff;
$lilacMain: #f8eeff;
$background: #f7fbff;
$redSphere: #fe0000;
$orangeSphere: #fd8c00;
$yellowSphere: #d2af00;
$greenSphere: #119f0b;
$blueSphere: #528ff9;
$indigoSphere: #2b06a3;
$violetSphere: #c22edc;
$rainbowList: $redSphere, $orangeSphere, $yellowSphere, $greenSphere, $blueSphere, $indigoSphere, $violetSphere;
