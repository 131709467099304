#snuffle, #estella, .evol-espireth {
  color: #c22edc;
}

#serena, #cal, .godshi-enpire {
  color: #2b06a3;
}

#nooka, #leandra, #ash, .bure-eshpel {
  color: #528ff9;
}

#baby-zero, #clairey, #katie, #charles, #fuchsia, #anxia-terra, #morgana, .geren-eshper {
  color: #119f0b;
}

#reckmund, #lolita-honey, .pyro-eshwelle {
  color: #d2af00;
}

#rose, #katherine, #honeysuckle, #jane, #kathy, #ava, #tara, .opree-shengra {
  color: #fd8c00;
}

#loretta-alice, #alicia, .pred-heres {
  color: #fe0000;
}

#clarence, #letitia, .peblash-reck {
  color: #464343;
}

#evol-espireth {
  background-color: #c22edc;
}

#godshi-enpire {
  background-color: #2b06a3;
}

#bure-eshpel {
  background-color: #528ff9;
}

#geren-eshper {
  background-color: #119f0b;
}

#pyro-eshwelle {
  background-color: #d2af00;
}

#opree-shengra {
  background-color: #fd8c00;
}

#pred-heres {
  background-color: #fe0000;
}

#peblash-reck {
  background-color: #464343;
}

header {
  padding: 1% 2% 0;
}

nav {
  height: 30px;
  width: 100%;
}
nav a {
  color: #2c3e50;
  font-weight: bold;
  margin-right: 10px;
}
nav a.router-link-exact-active {
  color: #c22edc;
}

#separatorStripe {
  background: -webkit-linear-gradient(to left, #fe0000, #fd8c00, #d2af00, #119f0b, #528ff9, #2b06a3, #c22edc);
  background: -moz-linear-gradient(to left, #fe0000, #fd8c00, #d2af00, #119f0b, #528ff9, #2b06a3, #c22edc);
  background: -o-linear-gradient(to left, #fe0000, #fd8c00, #d2af00, #119f0b, #528ff9, #2b06a3, #c22edc);
  background: linear-gradient(to left, #fe0000, #fd8c00, #d2af00, #119f0b, #528ff9, #2b06a3, #c22edc);
  height: 30px;
  opacity: 0.69;
  width: 100%;
}

#spheres {
  margin: 10px auto;
  width: 101px;
}
#spheres ::first-line {
  line-height: 39px;
}
#spheres #hew-espireth {
  background-color: #f8eeff;
}
#spheres #hew-espireth h2 {
  color: #2c3e50;
}
#spheres #godshi-enpire {
  height: 71px;
  padding-top: 5px;
}
#spheres #peblash-reck {
  height: 71px;
  padding-top: 5px;
}

.sphere {
  border: 2px solid black;
  border-radius: 40px;
  display: block;
  font-family: "Pragati Narrow", sans-serif;
  height: 76px;
  list-style-type: none;
  margin: 8px;
  opacity: 0.85;
  padding: 2px;
  text-align: center;
  width: 76px;
}
.sphere a {
  text-decoration: none;
}
.sphere h2 {
  color: white;
  font-size: 1rem;
  margin-top: 0;
  text-align: center;
}

.sphere:hover {
  opacity: 1;
}

@media screen and (min-width: 920px) {
  #spheres {
    height: 101px;
    width: 900px;
  }
  #spheres #godshi-enpire {
    height: 76px;
    padding-top: 0;
  }
  .sphere {
    display: inline-block;
    opacity: 0.7;
  }
}
#DarkStarVale #dsv-cast {
  display: flex;
  flex: 1 1 0px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}
#DarkStarVale .character {
  border: 1px solid #2c3e50;
  border-radius: 3px;
  margin: 10px;
}
#DarkStarVale .character a {
  text-decoration: none;
}
#DarkStarVale .character p {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.picture-info {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
}
.picture-info .picture {
  grid-area: 1/1/2/2;
}
.picture-info .info {
  grid-area: 2/1/3/2;
}
@media screen and (min-width: 720px) {
  .picture-info {
    grid-template-columns: 520px 1fr;
    grid-template-rows: 1fr;
  }
  .picture-info .picture {
    grid-area: 1/1/2/2;
  }
  .picture-info .info {
    grid-area: 1/2/2/3;
    margin-top: 25px;
  }
}

* {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: baseline;
}

#App {
  background-color: #f7fbff;
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100dvh;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  width: 100vw;
}

a {
  color: #2c3e50;
}

a:visited {
  color: #c22edc;
}

main {
  padding: 10px;
}
main .description li {
  margin-bottom: 14px;
}
main figure {
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-style: italic;
}
main h1 {
  font-size: 1.4em;
}
main h2 {
  display: inline;
  font-size: 1.2em;
  margin-top: 10px;
  text-align: left;
}
main img {
  border-radius: 3px;
  max-width: 500px;
  width: 100%;
}
main p {
  font-size: 0.8em;
}
main .subtitle {
  display: block;
  font-style: italic;
}

